import z from 'zod'

type User = any
type UserToken = string

enum UserGroup {
    Admin = 'Admin',
    Author = 'Author',
    Editor = 'Editor',
    User = 'User',
    Guest = 'Guest',
    // @dorian.live
    Staff = 'Staff',
}

const checkUserGroup = async (req: any, group: UserGroup): Promise<void> => {
    //@ts-ignore
    if (!req?.session?.groups?.includes(group)) {
        throw {
            status: 403,
            message: `not in '${group}' group`,
        }
    }
}

enum UserLoginProvider {
    Cognito = 'COGNITO',
    Google = 'Google',
    Facebook = 'Facebook',
    Apple = 'SignInWithApple',
    Amazon = 'LoginWithAmazon',
    Legacy = 'LEGACY',
}

enum UserTokenUse {
    Access = 'access',
    Identity = 'id',
}

type IdentityProvider = 'COGNITO' | 'Google' | 'Facebook' | 'LoginWithAmazon' | 'SignInWithApple'

interface CognitoAuthParameters {
    identity_provider?: IdentityProvider
    nonce?: string
}
interface UserAccessTokenData {
    sub: string
    "cognito:groups": UserGroup[]
    iss: string
    client_id: string
    origin_jti: string
    event_id?: string
    token_use: UserTokenUse.Access // access
    scope: string // space separated scopes
    auth_time: number // utc
    exp: number // utc
    iat: number // utc
    jti: string
    username: string
}

interface UserIdentity {
    userId: string
    providerName: string // Google
    providerType: string // Google
    issuer: unknown
    primary: 'true' | 'false'
    dateCreated: string
}

interface UserIdentityTokenData {
    sub: string
    "cognito:groups"?: UserGroup[]
    email_verified: boolean
    iss: string
    at_hash: string
    "cognito:username"?: string
    origin_jti: string
    aud: string
    preferred_username?: string
    event_id?: string
    token_use: UserTokenUse.Identity // id
    auth_time: number
    nickname: string
    family_name?: string
    given_name?: string
    exp: number // utc
    iat: number // utc
    jti: string
    email: string
    groups?: UserGroup[]
    identities?: UserIdentity[]
    picture?: string
}

type UserTokenData = UserAccessTokenData | UserIdentityTokenData

interface UserExtraData {

}

interface LegacyUserData {
    engine_user_id: number
    api_user_id?: number
    server_id?: string
    player_id?: string
    partial?: boolean
}

interface UserPublicData {
    sub: string
    username: string
    nickname: string
    email: string
    login_provider: UserLoginProvider
    email_verified: boolean
    iat: number
    exp: number
    auth_time: number
    family_name?: string
    given_name?: string
    groups: UserGroup[]
    signup_date: number
    hash: string
    picture: string
    legacyApi?: any
    legacy?: LegacyUserData
}

export interface SessionDataFull {
    sub: string
    dorian_id?: string
    cognito_id?: string
    username: string
    hash: string
    preferred_username: string,
    login_provider: UserLoginProvider
    token: UserAuthentication
    profile: UserTokenData
    groups: UserGroup[]
    legacy: false
}

export interface SessionDataLegacy {
    sub: string
    dorian_id?: string
    cognito_id: undefined
    username: string
    hash: string
    profile: any
    token: any
    preferred_username: string,
    login_provider: UserLoginProvider
    groups: UserGroup[]
    legacy: true
    legacy_user_id: number
    legacy_profile: LegacyUser
    legacy_username?: string
    legacy_dorian_id?: string
    legacy_player_id?: string
    legacy_server_id?: string
    legacy_api_user_id?: number
}

export interface UserSessionExtra {
    public_profile: UserPublicData
}

export interface NewLegacyUser {
    password: string | null
    role: 'admin' | 'basic_user'
    fullname: string
    firstName: string
    lastName: string
    email: string
    email_verified: boolean
    contract: boolean
    approved: boolean
    uuid: string
}

export interface NewLegacyPlayer {
    email: string
    dorian_id: string
    player_id: string
    server_id: string
    legacy: boolean
    username: string
}

export interface LegacyPlayer {
    id: number
    username: string
    dorian_id: string
    player_id: string
    server_id: string
    email: string
    device_tag: string
    is_primary: number
    is_creator: number
    avatar: string
    created_at: string
    updated_at: string
    build: string
    client: string
    platform: string
    os: string
    firebase_token: string
    ua_channel: string
    push_token: string
    utc_diff: number
    first_retention_day: string
    last_system_mail_id: number
    last_reward_received: number
    daily_reward_count: number
    avatar_url: string
    custom_pic: number
    experience: number
    story_count: number
    decision_count: number
    hearts: number
    hearts_spent: number
    hearts_starting_balance: number
    friend_count: number
    last_activity: number
    developer_mode: number
    data_dumped: number
    force_data_dump: number
    force_data_sync: number
    coins: number
    coins_spent: number
    coffee: number
    coffee_spent: number
    delete_requested: number
    legacy: number
}

export interface LegacyUser {
    id: number
    uuid: string
    email: string
    username: string
    password: string
    firstName: string
    imageId?: number
    lastName: string
    about?: string
    fullname?: string
    country: string
    createdAt: Date
    updatedAt: Date
    latestActivity: Date
    legalAcceptedAt?: Date
    appAccessDate?: Date
    role: 'admin' | 'basic_user' | 'user'
    email_verified: boolean
    contract: boolean
    accepted: boolean
    monetized: boolean
    canEditLegalText: boolean
    promptInvite: boolean
    appWizard: boolean
    receive_email: boolean
    device: 'Android' | 'iOS' | undefined
    approved: boolean
    showAnalytic: boolean
    isVerified: boolean
    adminShowApproved: boolean
    allowedFanSignups: number
    allowedCreatorSignups: number
    privateAppAccess: boolean
    legacy: boolean
    stub: boolean
}

export const VALID_LEGACY_ROLES = ['admin', 'basic_user']


// import { Request as _Request } from "express"
// import type { Session } from "express-session"

export type SessionWithUser = (SessionDataFull | SessionDataLegacy) & UserSessionExtra
// export type Request = _Request & {
//     session?: SessionWithUser;
// }
interface UserVerify {

}
interface UserReset {

}
interface UserConfirmSignup {
    UserConfirmed: boolean
    CodeDeliveryDetails: {
        Destination: string // "t***@e***",
        DeliveryMedium: string // "EMAIL",
        AttributeName: string // "email"
    }
    UserSub: string //"afd0bfd3-74c3-4718-a220-a01acdd1a56a"
}
interface UserAuthentication {
    AccessToken?: string
    ExpiresIn?: number // seconds
    TokenType?: string // Bearer
    RefreshToken?: string
    type?: string
    error?: string
}
interface UserData {

}

const ZUserPropUsername = z.string().min(3)
const ZUserPropPhone = z.string().min(3)
const ZUserPropEmail = z.string().email()
const ZUserPropPassword = z.string().min(8)
const ZUserPropToken = z.string()
const ZUserPropCode = z.string()
const ZUserPropAuthCode = z.string().regex(/^\w[\w\-]+\w$/, 'Bad authorization code format')
const ZUserPropGroup = z.enum(['Admin', 'Editor', 'Author', 'User'])
const ZUserLegacyMode = z.boolean().default(false)

const ZUserAuthenticate = z.object({
    username: ZUserPropUsername,
    password: ZUserPropPassword,
    legacy: ZUserLegacyMode,
})

const ZUserAuthenticateLegacy = z.object({
    email: ZUserPropEmail,
    password: ZUserPropPassword,
})

const ZUserToGroup = z.object({
    username: ZUserPropUsername,
    group: ZUserPropGroup,
})

const ZUserRegister = z.object({
    method: z.literal('EMAIL'),
    email: ZUserPropEmail,
    phone: z.undefined(),
    username: ZUserPropUsername,
    password: ZUserPropPassword,
    mobile: z.coerce.boolean().default(false),
})
const ZUserSignout = z.object({
    token: ZUserPropToken,
})
const ZUserValidate = z.object({
    token: ZUserPropToken,
})
const ZUserDecode = z.object({
    token: ZUserPropToken,
})
const ZUserReset = z.object({
    username: ZUserPropUsername,
    mobile: z.coerce.boolean().default(false),
})
const ZUserResend = z.object({
    username: ZUserPropUsername,
    resetPassword: z.coerce.boolean().default(false),
    mobile: z.coerce.boolean().default(false),
})
const ZUserResetConfirm = z.object({
    username: ZUserPropUsername,
    verificationCode: ZUserPropCode,
    newPassword: ZUserPropPassword,
})
const ZUserDelete = z.object({
    username: ZUserPropUsername,
    token: ZUserPropToken,
})
const ZUserRefresh = z.object({
    username: ZUserPropUsername,
    refreshToken: ZUserPropToken,
})
const ZUserVerify = z.object({
    username: ZUserPropUsername,
    code: ZUserPropCode,
})
const ZUserAuthInitiate = z.object({
    origin: z.string().optional(),
    provider: z.enum(['COGNITO', 'Google', 'Facebook', 'LoginWithAmazon', 'SignInWithApple']).optional(),
    platform: z.string().optional(),
})
const ZUserAuthExternal = z.object({
    email: ZUserPropEmail,
    providerUserId: ZUserPropToken,
    provider: z.enum(['COGNITO', 'Google', 'Facebook', 'LoginWithAmazon', 'SignInWithApple']),
    excludeExternalProviders: z.coerce.boolean().default(false),
    excludeInactiveUsers: z.coerce.boolean().default(true)
})
const ZUserDeAuth = z.object({
    origin: z.string().optional(),
    platform: z.string().optional(),
})
const ZUserDeAuthComplete = z.object({
    state: z.string().optional(),
})
const ZUserAuthComplete = z.object({
    code: ZUserPropAuthCode,
    state: z.string().optional(),
})
const ZUserAuthBranchIOComplete = z.object({
    code: ZUserPropAuthCode,
    platform: z.string().optional(),
})
const ZUserLegacyPlayerServer = z.object({
    serverId: z.string().uuid().optional(),
    playerId: z.string().uuid().optional(),
})
const ZUserSetData = z.object({
    username: ZUserPropUsername,
    customData: z.any(),
    token: ZUserPropToken
})
const ZUserSetUsername = z.object({
    username: ZUserPropUsername,
})
const ZUserGetData = z.object({
    username: ZUserPropUsername,
    token: ZUserPropToken
})
const ZUserList = z.object({
    limit: z.coerce.number().max(60).default(50),
    group: ZUserPropGroup.optional()
})
const ZUserPublicImage = z.object({
    user_hash: z.string().regex(/[a-zA-Z0-9]+/,'bad input')
})

type UserAuthenticationProps = z.infer<typeof ZUserAuthenticate>
type UserRegisterProps = z.infer<typeof ZUserRegister>
type UserValidateProps = z.infer<typeof ZUserValidate>
type UserDecodeProps = z.infer<typeof ZUserDecode>
type UserResetProps = z.infer<typeof ZUserReset>
type UserVerifyProps = z.infer<typeof ZUserVerify>
type UserDeleteProps = z.infer<typeof ZUserDelete>
type UserRefreshProps = z.infer<typeof ZUserRefresh>
type UserResendProps = z.infer<typeof ZUserResend>
type UserSetDataProps = z.infer<typeof ZUserSetData>
type UserGetDataProps = z.infer<typeof ZUserGetData>
type UserToGroupProps = z.infer<typeof ZUserToGroup>
type UserSignoutProps = z.infer<typeof ZUserSignout>
type UserAuthCompleteProps = z.infer<typeof ZUserAuthComplete>
type UserListProps = z.infer<typeof ZUserList>
type UserPublicImageProps = z.infer<typeof ZUserPublicImage>


// Zod Schema
export {
    ZUserAuthenticate,
    ZUserAuthenticateLegacy,
    ZUserRegister,
    ZUserValidate,
    ZUserDecode,
    ZUserReset,
    ZUserResetConfirm,
    ZUserVerify,
    ZUserDelete,
    ZUserRefresh,
    ZUserResend,
    ZUserSetData,
    ZUserGetData,
    ZUserSetUsername,
    ZUserToGroup,
    ZUserSignout,
    ZUserAuthInitiate,
    ZUserAuthComplete,
    ZUserAuthBranchIOComplete,
    ZUserLegacyPlayerServer,
    ZUserList,
    ZUserPublicImage,
    ZUserDeAuth,
    ZUserDeAuthComplete,
    ZUserAuthExternal,
}

// helpers
export {
    checkUserGroup,
}

// enums
export {
    UserTokenUse,
    UserGroup,
    UserLoginProvider,
}

// Derived Types
export type {
    User,
    UserToken,
    UserTokenData,
    UserIdentityTokenData,
    UserPublicData,
    UserExtraData,
    UserConfirmSignup,
    UserAuthentication,
    UserReset,
    UserVerify,

    // Endpoint Request Props
    UserAuthenticationProps,
    UserRegisterProps,
    UserValidateProps,
    UserDecodeProps,
    UserResetProps,
    UserVerifyProps,
    UserDeleteProps,
    UserRefreshProps,
    UserResendProps,
    UserSetDataProps,
    UserGetDataProps,
    UserToGroupProps,
    UserSignoutProps,
    UserAuthCompleteProps,
    UserListProps,
    UserPublicImageProps,

    CognitoAuthParameters,
    IdentityProvider,
}
