import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useServices } from '../components/Services'
import { Fallback } from '../components/Fallback'
import { LoginModal } from '../components/LoginModal'

import LockIcon from '@mui/icons-material/Lock'
import defaultLoginSchema from '../../schema/user/login'
import { UserGroup } from '../../types/user'

import '../assets/securecontainer.css'

interface SecureContainerProps {
  groups?: UserGroup[]
}

const SecureContainer = ({
  groups = [],
}: SecureContainerProps) => {
  const navigate = useNavigate()
  const [loginVisible, setLoginVisible] = useState(false)
  const { loading, user } = useServices()

  const isAuthenticated = useMemo<boolean>(() =>
    (user?.data.authenticated || false), [user])
  const isAuthorized = useMemo<boolean>(() =>
    (groups.length < 1) || (groups.some(group => user?.data?.profile?.groups?.includes(group))), [user, groups])

  useEffect(() => {
    if (loading) {
      return
    }

    if (!isAuthenticated && !loginVisible) {
      setLoginVisible(true)
    }
  }, [user, loading])

  if (isAuthenticated) {
    if (isAuthorized) {
      return <Outlet />
    }
    return <Fallback
      label={'Insufficient Authorisation'}
      icon={<LockIcon sx={{ fontSize: '8em', }} />}
    />
  }

  return <>
    <LoginModal
        schema={defaultLoginSchema}
        modal={loginVisible}
        setModal={setLoginVisible}
        onSuccess={(profile) => {
          console.log('logged in', profile)
          user?.reload()
        }}
        onFailure={() => {
          navigate(-1)
        }}
    />
    {/*
    //@ts-ignore*/}
    <Helmet>
      <title>Login</title>
    </Helmet>
    <Fallback
      label={'Authenticating...'}
    />
  </>
}

export {
  SecureContainer,
}
